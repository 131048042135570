/*-----------------------------------------------------------------------------------

	variables.less

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Base Colors
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Line Height Variables
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Margins
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Font Families
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Font Sizes
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Layouts
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Top Bar
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Header
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Primary Menu
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Page Title
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Portfolio
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Shop
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Blog
-----------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------
	mixins.less
-----------------------------------------------------------------------------------*/
/* Portfolio Item Size Builder
-----------------------------------------------------------------*/
/* Shop Item Size Builder
-----------------------------------------------------------------*/
/* Blog Grid Item Size Builder
-----------------------------------------------------------------*/
/* Other Mixins
-----------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------

	font

-----------------------------------------------------------------------------------*/
@font-face {
  font-family: 'StRydeRegular';
  src: url('/external/public/theme/css/fonts/2F05D6_0_0.eot');
  src: url('/external/public/theme/css/fonts/2F05D6_0_0.eot?#iefix') format('embedded-opentype'), url('/external/public/theme/css/fonts/2F05D6_0_0.woff2') format('woff2'), url('/external/public/theme/css/fonts/2F05D6_0_0.woff') format('woff'), url('/external/public/theme/css/fonts/2F05D6_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'StRyde-Bold';
  src: url('/external/public/theme/css/fonts/2F05D6_1_0.eot');
  src: url('/external/public/theme/css/fonts/2F05D6_1_0.eot?#iefix') format('embedded-opentype'), url('/external/public/theme/css/fonts/2F05D6_1_0.woff2') format('woff2'), url('/external/public/theme/css/fonts/2F05D6_1_0.woff') format('woff'), url('/external/public/theme/css/fonts/2F05D6_1_0.ttf') format('truetype');
}
/*-----------------------------------------------------------------------------------

	header.less

-----------------------------------------------------------------------------------*/
/* Primary Menu - CALL TO ACTION
-----------------------------------------------------------------*/
#primary-menu > ul > li > a.cta {
  color: #d83547;
}
@media (min-width: 991px) {
  #primary-menu > ul > li > a.cta {
    height: 39px;
    line-height: 38px;
    margin: 30px 0 30px 20px;
    border-radius: 2px;
    border: 1px solid #d83547;
    padding: 0 15px;
  }
  #primary-menu > ul > li > a.cta.inverse {
    background: #d83547;
    color: #FFF;
  }
  #header.sticky-header:not(.static-sticky) #primary-menu > ul > li > a.cta {
    padding-top: 0;
    padding-bottom: 0;
  }
  #primary-menu > ul > li > a.cta i {
    line-height: 38px;
  }
  #primary-menu > ul > li:hover > a.cta,
  #primary-menu > ul > li.current > a.cta {
    background: #d83547;
    color: #FFF;
  }
  #primary-menu > ul > li:hover > a.cta.inverse,
  #primary-menu > ul > li.current > a.cta.inverse {
    border-radius: 2px;
    border: 1px solid #d83547;
    background: #FFF;
    color: #d83547;
  }
  .sticky-header #primary-menu > ul > li > a.cta {
    height: 39px !important;
    margin: 10px 0 10px 20px;
    line-height: 38px !important;
  }
}
/*-----------------------------------------------------------------------------------
    Dark Scheme - less/dark.less
-----------------------------------------------------------------------------------*/
.dark.bgcolor .feature-box h3 {
  color: #FFF;
}
.dark.bgcolor .feature-box h3 span.subtitle {
  color: #000;
}
.dark.bgcolor .feature-box p {
  color: #EEE;
}
.dark.bgcolor .feature-box p a {
  color: #000;
  text-decoration: underline !important;
}
.dark.bgcolor .feature-box p a:hover {
  color: #FFF;
}
/*-----------------------------------------------------------------------------------
    NW customise home page
-----------------------------------------------------------------------------------*/
.bgcolor.dark .fbox-border.fbox-effect.fbox-dark .fbox-icon i:after,
.bgcolor #header-wrap.dark .fbox-border.fbox-effect.fbox-dark .fbox-icon i:after {
  background-color: #DC4959;
}
.bgcolor .fbox-border.fbox-effect .fbox-icon i:after,
.bgcolor #header-wrap .fbox-border.fbox-effect .fbox-icon i:after {
  background-image: none;
}
/*-----------------------------------------------------------------------------------
    Logo should be centered on mobile
-----------------------------------------------------------------------------------*/
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 991px), not all, only screen and (max-width: 991px) and (-webkit-min-device-pixel-ratio: 2), only screen and (max-width: 991px) and (min-resolution: 192dpi), only screen and (max-width: 991px) and (min-resolution: 2dppx) {
  #logo a.standard-logo {
    display: none;
  }
  #logo a.retina-logo {
    display: inline-block;
  }
}
/*-----------------------------------------------------------------------------------
	colors.css
    Colors

    Replace the HEX Code with your Desired Color HEX
-----------------------------------------------------------------------------------*/
::-moz-selection {
  background: #d83547;
}
::selection {
  background: #d83547;
}
::-moz-selection {
  background: #d83547;
}
::-webkit-selection {
  background: #d83547;
}
/*-----------------------------------------------------------------------------------
	bootstrap.css
-----------------------------------------------------------------------------------*/
.btn-primary {
  background-color: #d83547;
  border-color: #d83547;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-color: #C52E3C;
  border-color: #C52E3C;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #d83547;
  border-color: #d83547;
}
.btn-primary .badge {
  color: #d83547;
}
.btn-danger {
  background-color: #d83547;
  border-color: #d83547;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background-color: #d83547;
  border-color: #d83547;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #d83547;
  border-color: #d83547;
}
.btn-danger .badge {
  color: #d83547;
}
/*-----------------------------------------------------------------------------------
	footer.less
-----------------------------------------------------------------------------------*/
#footer address {
  margin-bottom: 10px;
}
#footer .contacts {
  font-size: 12px;
}
.footer-logo {
  display: inline-block;
  margin: -30px 30px 20px 0;
  max-width: 75%;
}
/*-----------------------------------------------------------------------------------
	sliders.less
-----------------------------------------------------------------------------------*/
.slider-caption h2 {
  font-weight: 600;
  margin-bottom: 20px;
}
.slider-caption p {
  margin-bottom: 30px;
}
/*-----------------------------------------------------------------------------------
	typography.less
-----------------------------------------------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 30px 0 10px 0;
}
h4,
h5,
h6 {
  margin-top: 10px;
}
p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form {
  margin-bottom: 20px;
}
/*-----------------------------------------------------------------------------------
	headings.less
-----------------------------------------------------------------------------------*/
.center .heading-block,
.heading-block.center,
.heading-block.title-center {
  padding-left: 50px;
  padding-right: 50px;
}
/*-----------------------------------------------------------------------------------
	buttons.less
-----------------------------------------------------------------------------------*/
.button-red {
  background-color: #d83547;
}
.button-3d.button-red:hover,
.button-reveal.button-red:hover {
  background-color: #d83547 !important;
}

/*# sourceMappingURL=all_8.css.map */
